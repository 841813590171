.ScheduleView-Background {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: url('../Images/blob-scene-haikei.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); /* Support for Safari */
}


/* .ScheduleView-Background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: -1;
} */

.ScheduleView-Container {
  width: 80%;
  max-width: 1200px;
  min-width: 1100px;
  height: 87.5%;
  border: solid 1px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  display: flex;
  overflow: hidden;

  background: white;
  box-shadow: 0px 0.7px 1.7px rgba(0, 0, 0, 0.028),
    0px 2.2px 5.6px rgba(0, 0, 0, 0.042), 0px 10px 25px rgba(0, 0, 0, 0.07);
}

.ScheduleView-Sidebar {
  width: 30%;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.User-Info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  border-bottom: solid 1px rgba(0, 0, 0, 0.15);

  width: 100%;
  height: 45%;

  padding: 1em 1.5em;
}

.User-Header {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;

  position: relative;
}

.User-Header-Arrow {
  position: absolute;
  right: 1em;
}

.User-Description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  min-width: 100%;
  width: 100%;
  height: 100%;
  margin-top: 1em;
}

.Event-Details,
.Detail-Item,
.Event-Timezone {
  flex: 0 0 auto;
}

.Event-Details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1em;
  width: 100%;
}

.Detail-Item {
  display: flex;
  align-items: center;
  justify-content: center; /* Align items to the center */
  gap: 0.25em;
  padding: 0.25em 0.75em; /* Add padding */
  border: solid 1px rgba(0, 0, 0, 0.15); /* Add border */
  border-radius: 15px; /* Add border radius */
}

.Event-Timezone {
  display: flex;
  align-items: center;
  justify-self: flex-end;
  gap: 0.5em;

  width: 100%;
}

.Event-Scheduling-Info {
  display: flex;
  align-items: center;
  gap: 1em;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.Timezone-Item {
  flex-grow: 1;
}

.Calendar {
  width: 100%;
  height: 55%;
  padding: 1em;
  padding-top: 0em;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  overflow: hidden;
}

.ScheduleView-Content {
  width: 70%;
  height: 100%;
  background: rgba(0, 0, 0, 0.02);
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.ScheduleView-Header {
  width: 100%;
  height: 12.5%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #fff;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  padding: 1em;
}

#BlueMail-Logo {
  width: 40px;
  height: 40px;
  background: url("../Images/BlueMail-App-icon.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.ScheduleView-Display {
  width: 100%;
  height: 87.5%;
  overflow-y: auto;
  padding: 1em;
}

.ScheduleView-Day {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;

  margin-bottom: 1em;
  padding-bottom: 1em;
  margin-bottom: 0.5em;
}

.ScheduleView-Day-Title {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 0.5em;

  margin-bottom: 0.75em;
  padding-bottom: 0.5em;

  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
}

.ScheduleView-TimeSlots {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1em;
}

.TimeSlot {
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 0.45em 1em;

  width: 125px;
  cursor: pointer;
}

.BlueMail-Footer {
  display: none;
}

@media only screen and (max-width: 600px) {
  .ScheduleView-Container {
    max-height: 100vh;
    min-height: 100%;
    max-width: 100vw;

    border-radius: 0;
    border: none;
    box-shadow: none;

    flex-direction: column;
  }

  .ScheduleView-Sidebar {
    width: 100%;
    height: auto;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    padding: 0;

    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  }

  #BlueMail-Logo-Footer {
    width: 30px;
    height: 30px;
    background: url("../Images/BlueMail-App-icon.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .User-Info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    border-bottom: 0;

    width: 100%;
    height: 55%;

    padding: 1em 1.5em;
  }

  .Calendar {
    width: 100%;
    align-items: center;

    height: auto;
    padding: 0;
    margin-top: 0.5em;
    margin-bottom: 0.75em;
  }

  .ScheduleView-Content {
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0.02);
    overflow: hidden;

    display: flex;
    flex-direction: column;
  }

  .ScheduleView-Header {
    width: 100%;
    height: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background: #fff;
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
    padding: 1em;
  }

  .ScheduleView-Display {
    width: 100%;
    height: auto;
    overflow-y: auto;
  }

  #BlueMail-Logo {
    display: none;
  }

  .ScheduleView-Display {
    width: 100%;
    height: auto;
    overflow-y: auto;
    padding: 1em;
  }

  .ScheduleView-Day {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    margin-bottom: 1em;
    padding-bottom: 1em;
    margin-bottom: 0.5em;
  }

  .ScheduleView-Day-Title {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 0.5em;

    margin-bottom: 0.75em;
    padding-bottom: 0.5em;

    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  }

  .ScheduleView-TimeSlots {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 1em;

    width: 100%;
  }

  .ScheduleView-Timeslot {
    width: 100%;
    height: 50px;
  }

  .TimeSlot {
    min-height: 100%;
    min-width: 100%;
    background: #fff;
  }

  .BlueMail-Footer {
    display: flex;
    min-width: 100%;
    background: white;
    color: #134fb4;
    padding: 0.75em 1em;
    border-top: solid 1px rgba(0, 0, 0, 0.15);

    display: flex;
    align-items: center;
    gap: 0.5em;
  }
}

@media only screen and (max-width: 1200px) {
  .ScheduleView-Container {
    min-width: 100%;
  }
}