.Meeting-Container {
    width: 100%;
    height: 100%;

    padding: 1em 2em;
    /* #1f6bf1 */
}

.Meeting-Header {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0em 1em;
}

.Meeting-Header-Logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.MeetingLinks-Header {
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 0em;
}

.MeetingLinks-Map {
    width: 100%;
    height: 550px;

    overflow: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    padding: 1em 0em;
}

.MeetingLinks-Container {
    width: 100%;
    border: solid 1px rgba(0, 0, 0, 0.1);

    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0.5em 1em;

    border-left: solid 15px #134fb4;
}

.MeetingLinks-Title {
    display: flex;
    flex-direction: column;
}

.MeetingLinks-Buttons {
    display: flex;
    align-items: center;
    gap: 1em;
}

.Event-Input {
    display: flex;
    align-items: center;
    gap: 1em;

    margin-top: 0.5em;
    width: 100%;
}

.Availability-Header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.Availability-Title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Availability-Grid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0em;

    width: 100%;
    height: 100%;

    margin-top: 0.25em;
}

.Availability-Item {
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);

    width: 100%;
    height: 100%;

    padding: 1em;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 1em;
    justify-content: space-between;
}

.Availability-Inputs {
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 0.5em;
}

@media only screen and (max-width: 600px) {
    #BlueMail-Title {
        display: none;
       }
    .MeetingLinks-Map {
        height: calc(100vh - 300px);
    }
}

@media only screen and (max-width: 1100px) {
    .Meeting-Container {
        width: 100%;
        border: none;
    }
}

