.EnterpriseTabs-Container {
    width: 100%;
    height: 100%;
    min-height: 800px;
    padding: 1em;

    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 1em;

    overflow: auto;
}

.Dashboard-Tab-Container {
    width: 100%;
    height: 100%;
}

.Dashboard-Tab-Container-Content {
    width: 100%;
    height: 100%;

    margin-top: 0.25em;

    border-top: solid 1px rgba(0, 0, 0, 0.15);

    padding: 1em;

    min-height: 500px;

    overflow: auto;

    gap: 1em;
}

.EnterpriseDashboard-Content-Left {
    width: 70%;
    height: 100%;

    display: flex;
    flex-direction: column;

    gap: 2.5em;
}

.EnterpriseDashboard-Content-Right {
    width: 30%;
    height: 100%;
    
    display: flex;
    flex-direction: column;

    gap: 2.5em;
}

#EnterpriseTeamsTab {
    flex-direction: column;
    height: 100%;

    align-items: flex-start;
    justify-content: flex-start;
    overflow: auto;
}

.EnterpriseTeams-Content-Top {
    width: 100%;
    height: 100%;

    display: flex;
    gap: 1em;
}

#Dashboard-Tab-TeamManagement {
    width: 100%;
    height: 100%;
}

#Dashboard-Tab-TeamManagement .Dashboard-Tab-Container-Content {
    display: flex;
    align-items: flex-start;
    overflow: auto;
    flex-wrap: warp;
}

#Dashboard-Tab-TeamMembers {
    width: 30%;
}

#Dashboard-Tab-TeamMembers .Dashboard-Tab-Container-Content {
    display: flex;
    flex-direction: column;
    height: 500px;

    gap: 0.5em;

    overflow: auto;

    position: relative;
}

.TeamManagement-Card {
    width: 225px;
    min-width: 225px;
    height: 100%;
    min-height: 285px;

    border-radius: 10px;
    border: solid 1px rgba(0, 0, 0, 0.15);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    padding: 0.75em 0.65em;

    position: relative;
}

.TeamManagement-Card-Header {
    width: 100%;

    display: flex;
    justify-content: space-between;
}

.TeamManagement-Card-Header-Avatars {
    display: flex;
}

.TeamManagement-Card-Content {
    width: 100%;

    margin-top: 0.75em;
}

.TeamManagement-Card-Data {
    width: 100%;

    position: absolute;
    bottom: 0.5em;

    padding: 0.35em 0.65em;
}

.TeamMembers-Header {
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1em;

    position: absolute;
    bottom: 0;
    right: 0;

    background: white;

}

.TeamMembers-Card {
    width: 100%;
    height: 60px;

    border-radius: 7.5px;
    border: solid 1px rgba(0, 0, 0, 0.15);
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0.75em 0.5em;
}

.EnterpriseTeams-Content-Bottom {
    width: 100%;
    height: 50%;

    display: flex;
    gap: 1em;
}

#Dashboard-Tab-TeamSchedule {
    width: 70%;
}

#Dashboard-Tab-TeamSchedule .Dashboard-Tab-Container-Content {
    display: flex;
    align-items: flex-start;
    height: 500px;

    overflow: auto;
}

.TeamSchedule-Card {
    border: solid 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    
    height: 285px;
    width: 225px;
    min-width: 225px;

    padding: 0.75em 0.65em;
    padding-bottom: 0.25em;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .EnterpriseTeams-Content-Top {
        flex-direction: column;
        overflow: auto;
        height: calc(100vh - 225px);
    }

    #Dashboard-Tab-TeamSchedule {
        width: 100%;
    }

    #Dashboard-Tab-TeamMembers {
        width: 100%;
    }

    #Dashboard-Tab-TeamManagement .Dashboard-Tab-Container-Content {
        flex-direction: column;
        align-items: center;
        height: calc(100vh - 225px);
    }
  }
  
  @media only screen and (max-width: 1100px) {
}
  