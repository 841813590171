.EnterpriseDashboard-Container {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1em 2em;
  padding-top: calc(55px + 1em);
  border-radius: 15px;

  /* background: #134fb4; */
}

.EnterpriseDashboard-Tabs {
  width: 100%;
  height: 100%;

  border-radius: 10px;
  border: solid 0px rgba(0, 0, 0, 0.15);

  padding: 1em;

  overflow: hidden;

  background: white;
}

.NavbarContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.NavbarContainer.open {
  transform: translateX(0);
}


@media only screen and (max-width: 600px) {
  .EnterpriseDashboard-Container {
    max-width: 100vw;
    width: 100%;
    padding: 0;
    padding-top: 4em;
  }

  .EnterpriseDashboard-Tabs {
    border: none;
  }
}

@media only screen and (max-width: 1100px) {
  .EnterpriseDashboard-Tabs {
    width: 100%;
    max-height: 100vh;
  }
}

