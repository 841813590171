.tip-content {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
  }
  
  .tip-content.in {
    opacity: 1;
  }
  
  .tip-content.out {
    opacity: 0;
  }
  